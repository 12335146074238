/*begin our styles*/
 
body {
    font: 16px/1.4em ’PT Sans’, sans-serif;;
    color: #1c1c1c;
}
 
p,
ul {
    margin: 0 0 1.5em;
}
 
ul {
    list-style: disc;
    padding: 0 0 0 20px;
}
 
a {
    color: #1D745A;
}
 
h1 {
 
}
 
h2 {
    font-family: ’PT Serif’, serif;
    font-size: 32px;
    line-height: 1.4em;
    margin: 0 0 .4em;
    font-weight: bold;
}
 
/*layout*/
 
#wrapper {
    height: 100%; 
    overflow: auto; 
    width: 100%; 
    position: relative; 
}

#content {
    margin-top: 50px; 
}

#top-nav-lg {
    position: relative; 
    height: 100px; 
    width: 100%; 
}

#logo-img-lg {
    /* width: 150px;  */
    width: auto; 
    height: 75px; 
}

#top-nav-sm {
    position: fixed; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 50px; 
    z-index: 10000; 
}


 
/*shorter clearfix http://nicolasgallagher.com/micro-clearfix-hack/*/
header:before,
header:after {
    content:"";
    display:table;
}
    
header:after {
    clear:both;
}
    
/* For IE 6/7 (trigger hasLayout) */
header {
    zoom:1;
}

#logo-img-sm {
    float: left; 
    width: 50px; 
    height: 30px; 
}


article {
    border-bottom: 1px solid #d8d8d8;
    padding: 10px 20px 0 20px;
    margin: 10px 0;
}

/**
 * Events 
 */

#events {
    border: solid 1px #999; 
}

#events .event {
    width: 98%; 
    margin-left: 1%; 
    margin-right: 1%; 
    position: relative; 
}
#events .event img {
    max-width: 100%; 
    height: auto; 
}
 
/*header*/
 
header {
    background: #1c1c1c;
    /* padding: 15px 20px; */
}

h1.logo a {
    color: #d8d8d8;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 22px;
    float: left;
    letter-spacing: 0.2em;
}
 
a.to_nav {
    float: right;
    color: #fff;
    background: #4e4e4e;
    text-decoration: none;
    padding: 0 10px;
    font-size: 12px;
    font-weight: bold;
    line-height: 22px;
    height: 22px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}
 
a.to_nav:hover,
a.to_nav:focus {
    color: #1c1c1c;
    background: #ccc;
}

/*navigation*/ 
 
#primary_nav ul {
    list-style: none;
    background: #1c1c1c;
    padding: 5px 0;
}
 
#primary_nav li a {
    display: block;
    padding: 0 20px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    letter-spacing: 0.1em;
    line-height: 2em;
    height: 2em;
    border-bottom: 1px solid #383838;
}
 
#primary_nav li:last-child a {
    border-bottom: none;
}
 
#primary_nav li a:hover,
#primary_nav li a:focus {
    color: #1c1c1c;
    background: #ccc;
}
 
/*footer*/
 
footer {
    font-family: ’PT Serif’, serif;
    font-style: italic;
    text-align: center;
    font-size: 14px;
}

@media only screen and (min-width: 768px) {
 
    a.to_nav {
        display: none;
    }
 
}

#logo-img { 
    max-width: 100%; 
    height: auto;   
}